@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,400;1,500;1,700);
@import url(https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300;400;500;600;700;800);
@import url(https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap);
/* Import css for antd */
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gh-portal-main-title {
    text-align: center;
    color: #333;
    color: var(--grey1);
    line-height: 1.35em;
}

.gh-portal-signup-logo+.gh-portal-main-title {
    margin: 4px 0 0;
}

.gh-portal-text-disabled {
    color: #474747;
    color: var(--grey3);
    font-weight: normal;
    opacity: 0.35;
}

.gh-portal-text-center {
    text-align: center;
}

.gh-portal-input-label {
    color: #333;
    color: var(--grey1);
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 2px;
    letter-spacing: 0.35px;
}

.gh-portal-setting-data {
    color: #7f7f7f;
    color: var(--grey6);
    font-size: 1.3rem;
    line-height: 1.15em;
}

.gh-portal-error {
    color: #f02525;
    color: var(--red);
    font-size: 1.4rem;
    line-height: 1.6em;
    margin: 12px 0;
}

/* Buttons
/* ----------------------------------------------------- */
.gh-portal-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: 0.2px;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    color: #1d1d1d;
    color: var(--grey0);
    background: #fff;
    background: var(--white);
    border: 1px solid #eaeaea;
    border: 1px solid var(--grey12);
    min-width: 80px;
    height: 42px;
    padding: 0 1.8rem;
    border-radius: 4px;
    cursor: pointer;
    transition: all .25s ease;
    box-shadow: none;
    box-shadow: 0 2px 6px -3px rgba(0, 0, 0, 0.19);
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    outline: none;
}

.gh-portal-btn:hover {
    border-color: #dcdcdc;
    border-color: var(--grey10);
}

.gh-portal-btn-icon svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    stroke: currentColor;
}

.gh-portal-btn-icon svg path {
    stroke: currentColor;
}

.gh-portal-btn-link {
    line-height: 1;
    background: none;
    padding: 0;
    height: unset;
    min-width: unset;
    box-shadow: none;
    border: none;
}

.gh-portal-btn-link:hover {
    box-shadow: none;
    opacity: 0.85;
}

.gh-portal-btn-branded {
    color: var(--brandcolor);
}

.gh-portal-btn-list {
    font-size: 1.4rem;
    color: var(--brandcolor);
    height: 38px;
    width: unset;
    min-width: unset;
    padding: 0 4px;
    margin: 0 -4px;
    box-shadow: none;
    border: none;
}

.gh-portal-btn-list:hover {
    box-shadow: none;
    opacity: 0.75;
}

.gh-portal-btn-logout {
    position: absolute;
    top: 22px;
    left: 24px;
    background: none;
    border: none;
    height: unset;
    color: #474747;
    color: var(--grey3);
    padding: 0;
    margin: 0;
    z-index: 999;
    box-shadow: none;
}

.gh-portal-btn-logout .label {
    opacity: 0;
    transform: translateX(-6px);
    transition: all 0.2s ease-in-out;
}

.gh-portal-btn-logout:hover {
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    height: unset;
    box-shadow: none;
}

.gh-portal-btn-logout:hover .label {
    opacity: 1.0;
    transform: translateX(-4px);
}

.gh-portal-logouticon {
    color: #c5c5c5;
    color: var(--grey9);
    cursor: pointer;
    width: 23px;
    height: 23px;
    padding: 6px;
    transform: translateX(0);
    transition: all 0.2s ease-in-out;
}

.gh-portal-logouticon path {
    stroke: #c5c5c5;
    stroke: var(--grey9);
    transition: all 0.2s ease-in-out;
}

.gh-portal-btn-logout:hover .gh-portal-logouticon {
    transform: translateX(-2px);
}

.gh-portal-btn-logout:hover .gh-portal-logouticon path {
    stroke: #474747;
    stroke: var(--grey3);
}

/* Global layout styles
/* ----------------------------------------------------- */
.gh-portal-popup-background {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.25);
    padding-top: 100px;
    -webkit-animation: fadein 0.2s;
            animation: fadein 0.2s;
}

.gh-portal-popup-background.preview {
    background: #EDF0F2;
    -webkit-animation: none;
            animation: none;
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1.0;
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1.0;
    }
}

.gh-portal-popup-wrapper {
    padding: 6vw 0;
}

.gh-portal-popup-container {
    max-height: calc(100vh - 12vw);
    outline: none;
    position: relative;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
    font-size: 1.5rem;
    text-align: left;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    background: #fff;
    background: var(--white);
    width: 400px;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 3.8px 2.2px rgba(0, 0, 0, 0.028), 0 9.2px 5.3px rgba(0, 0, 0, 0.04), 0 17.3px 10px rgba(0, 0, 0, 0.05), 0 30.8px 17.9px rgba(0, 0, 0, 0.06), 0 57.7px 33.4px rgba(0, 0, 0, 0.072), 0 138px 80px rgba(0, 0, 0, 0.1);
    -webkit-animation: popup 0.25s ease-in-out;
            animation: popup 0.25s ease-in-out;
}

.gh-portal-container-wide {
    width: 440px;
}

.gh-portal-container-desktop {
    top: 19px;
    max-width: 1396px;
    width: 100%;
    height: calc(100vh - 38px);
    max-height: unset;
}

.gh-portal-container-narrow {
    width: 388px;
}

.gh-portal-popup-container.preview {
    box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.02),
        0 2.8px 2.2px rgba(0, 0, 0, 0.02),
        0 6.7px 5.3px rgba(0, 0, 0, 0.028),
        0 12.5px 10px rgba(0, 0, 0, 0.035),
        0 22.3px 17.9px rgba(0, 0, 0, 0.042),
        0 41.8px 33.4px rgba(0, 0, 0, 0.05),
        0 100px 80px rgba(0, 0, 0, 0.07);
    -webkit-animation: none;
            animation: none;
}

.gh-portal-content-header {
    padding: 28px 24px 0px;
    background-color: #f9f9f9;
}

.gh-portal-content-header.desktop {
    background-color: transparent;
}

/* Sets the main content area of the popup scrollable.
/* 12vw is the sum horizontal padding of the popup container
*/
.gh-portal-content {
    overflow-y: scroll;
    padding: 28px 32px 32px;
    max-height: calc(100vh - 12vw);
}

.gh-portal-content.with-footer {
    overflow-y: scroll;
    padding-bottom: 0;
    max-height: calc(100vh - 12vw - 72px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.gh-portal-content::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.gh-portal-content {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.gh-portal-popup-container footer {
    padding: 0 32px 32px;
    height: 72px;
}

@-webkit-keyframes popup {
    0% {
        transform: scale(0.9) translateY(20px);
        opacity: 0;
    }

    75% {
        opacity: 1.0;
    }

    100% {
        transform: scale(1) translateY(0);
    }
}

@keyframes popup {
    0% {
        transform: scale(0.9) translateY(20px);
        opacity: 0;
    }

    75% {
        opacity: 1.0;
    }

    100% {
        transform: scale(1) translateY(0);
    }
}

.gh-portal-closeicon-container {
    position: absolute;
    top: 18px;
    right: 20px;
    z-index: 999;
}

.gh-portal-closeicon {
    color: #c5c5c5;
    color: var(--grey9);
    cursor: pointer;
    width: 16px;
    height: 16px;
    padding: 12px;
    transition: all 0.2s ease-in-out;
}

.gh-portal-closeicon:hover {
    color: #686868;
    color: var(--grey5);
}

.gh-portal-logout-container {
    position: absolute;
    top: 8px;
    left: 8px;
}

.gh-portal-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 24px;
}

.gh-portal-section {
    margin-bottom: 32px;
}

.gh-portal-section.form {
    margin-bottom: 20px;
}

.gh-portal-detail-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -4px 0 24px;
}

.gh-portal-detail-footer .gh-portal-btn {
    min-width: 90px;
}

.gh-portal-action-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gh-portal-list {
    background: #fff;
    background: var(--white);
    padding: 20px;
    border-radius: 3px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.05);
}

.gh-portal-list.client-list {
    padding: 0 20px;
}

.gh-portal-list.client-list section {
    margin: 0 -20px;
    padding: 20px;
}

.gh-portal-list.client-list section:last-of-type {
    margin-bottom: 0;
    padding-bottom: 20px;
    border: 1px solid #eaeaea;
    border: 1px solid var(--grey12);
}

.gh-portal-list.outline {
    box-shadow: none;
    border: 1px solid #eaeaea;
    border: 1px solid var(--grey12);
}

.gh-portal-list.outline section {
    box-shadow: none;
    border: 1px solid #eaeaea;
    border: 1px solid var(--grey12);
}

.gh-portal-list section {
    display: flex;
    /* align-items: center; */
    margin: 0 -20px 20px;
    padding: 0 20px 20px;
    border-bottom: 1px solid #eaeaea;
    border-bottom: 1px solid var(--grey12);
}

.gh-portal-list section:last-of-type {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
}

.gh-portal-list-detail {
    flex-grow: 1;
    flex-basis: 50%;
}

.gh-portal-list-detail.first {
    padding-right: 12px;
}

.gh-portal-list-detail h3 {
    font-size: 1.5rem;
    font-weight: 500;
}

.gh-portal-list-detail.info h3 {
    font-size: 1.3rem;
    font-weight: bold;
    color: #7f7f7f;
    color: var(--grey6);
}

.gh-portal-list-detail p {
    font-size: 1.3rem;
    /* letter-spacing: 0.3px; */
    line-height: 1.3em;
    padding: 0;
    margin: 2px 0 0;
    color: black;
    /* color: var(--grey6); */
}

.gh-portal-expire-warning {
    text-align: center;
    color: #f02525;
    color: var(--red);
    font-weight: 500;
    font-size: 1.4rem;
    margin: 12px 0;
}

/* Icons
/* ----------------------------------------------------- */
.gh-portal-icon {
    color: var(--brandcolor);
}

/* Spacing modifiers
/* ----------------------------------------------------- */
.mt1 {
    margin-top: 4px;
}

.mt2 {
    margin-top: 8px;
}

.mt3 {
    margin-top: 12px;
}

.mt4 {
    margin-top: 16px;
}

.mt5 {
    margin-top: 20px;
}

.mt6 {
    margin-top: 24px;
}

.mt7 {
    margin-top: 28px;
}

.mt8 {
    margin-top: 32px;
}

.mt9 {
    margin-top: 36px;
}

.mt10 {
    margin-top: 40px;
}

.mr1 {
    margin-right: 4px;
}

.mr2 {
    margin-right: 8px;
}

.mr3 {
    margin-right: 12px;
}

.mr4 {
    margin-right: 16px;
}

.mr5 {
    margin-right: 20px;
}

.mr6 {
    margin-right: 24px;
}

.mr7 {
    margin-right: 28px;
}

.mr8 {
    margin-right: 32px;
}

.mr9 {
    margin-right: 36px;
}

.mr10 {
    margin-right: 40px;
}

.mb1 {
    margin-bottom: 4px;
}

.mb2 {
    margin-bottom: 8px;
}

.mb3 {
    margin-bottom: 12px;
}

.mb4 {
    margin-bottom: 16px;
}

.mb5 {
    margin-bottom: 20px;
}

.mb6 {
    margin-bottom: 24px;
}

.mb7 {
    margin-bottom: 28px;
}

.mb8 {
    margin-bottom: 32px;
}

.mb9 {
    margin-bottom: 36px;
}

.mb10 {
    margin-bottom: 40px;
}

.ml1 {
    margin-left: 4px;
}

.ml2 {
    margin-left: 8px;
}

.ml3 {
    margin-left: 12px;
}

.ml4 {
    margin-left: 16px;
}

.ml5 {
    margin-left: 20px;
}

.ml6 {
    margin-left: 24px;
}

.ml7 {
    margin-left: 28px;
}

.ml8 {
    margin-left: 32px;
}

.ml9 {
    margin-left: 36px;
}

.ml10 {
    margin-left: 40px;
}

.pt1 {
    padding-top: 4px;
}

.pt2 {
    padding-top: 8px;
}

.pt3 {
    padding-top: 12px;
}

.pt4 {
    padding-top: 16px;
}

.pt5 {
    padding-top: 20px;
}

.pt6 {
    padding-top: 24px;
}

.pt7 {
    padding-top: 28px;
}

.pt8 {
    padding-top: 32px;
}

.pt9 {
    padding-top: 36px;
}

.pt10 {
    padding-top: 40px;
}

.pr1 {
    padding-right: 4px;
}

.pr2 {
    padding-right: 8px;
}

.pr3 {
    padding-right: 12px;
}

.pr4 {
    padding-right: 16px;
}

.pr5 {
    padding-right: 20px;
}

.pr6 {
    padding-right: 24px;
}

.pr7 {
    padding-right: 28px;
}

.pr8 {
    padding-right: 32px;
}

.pr9 {
    padding-right: 36px;
}

.pr10 {
    padding-right: 40px;
}

.pb1 {
    padding-bottom: 4px;
}

.pb2 {
    padding-bottom: 8px;
}

.pb3 {
    padding-bottom: 12px;
}

.pb4 {
    padding-bottom: 16px;
}

.pb5 {
    padding-bottom: 20px;
}

.pb6 {
    padding-bottom: 24px;
}

.pb7 {
    padding-bottom: 28px;
}

.pb8 {
    padding-bottom: 32px;
}

.pb9 {
    padding-bottom: 36px;
}

.pb10 {
    padding-bottom: 40px;
}

.pl1 {
    padding-left: 4px;
}

.pl2 {
    padding-left: 8px;
}

.pl3 {
    padding-left: 12px;
}

.pl4 {
    padding-left: 16px;
}

.pl5 {
    padding-left: 20px;
}

.pl6 {
    padding-left: 24px;
}

.pl7 {
    padding-left: 28px;
}

.pl8 {
    padding-left: 32px;
}

.pl9 {
    padding-left: 36px;
}

.pl10 {
    padding-left: 40px;
}

@media (max-width: 480px) {
    .gh-portal-popup-wrapper {
        height: 100%;
        padding: 0;
    }

    .gh-portal-popup-container {
        width: 100%;
        height: 100%;
        border-radius: 0;
        /* overflow-y: scroll; */
        -webkit-animation: popup-mobile 0.25s ease-in-out;
                animation: popup-mobile 0.25s ease-in-out;
        max-height: unset !important;
    }

    .gh-portal-content {
        overflow-y: auto !important;
        /* max-height: unset !important; */
    }
}

@media (max-width: 390px) {
    .gh-portal-plans-container {
        flex-direction: column;
    }

    .gh-portal-plan-section {
        flex-direction: row;
        min-height: 60px;
        border-right: none;
        border-bottom: 1px solid #dcdcdc;
        border-bottom: 1px solid var(--grey10);
    }

    .gh-portal-plan-section:last-of-type {
        border-bottom: none;
    }

    .gh-portal-plan-checkbox {
        order: 1;
        margin-left: 12px;
    }

    .gh-portal-plan-name {
        position: absolute;
        left: 40px;
        top: 12px;
        padding: 0;
        margin: 0;
        text-transform: none;
        font-size: 1.4rem;
        letter-spacing: 0.2px;
    }

    .gh-portal-plan-feature {
        position: absolute;
        left: 40px;
        top: 32px;
        padding: 0;
        margin: 0;
        width: unset;
        text-align: left;
        border-top: none;
        font-weight: 400;
        letter-spacing: 0.2px;
    }

    .gh-portal-plan-pricelabel {
        right: 20px;
        top: 12px;
        position: absolute;
    }

    .gh-portal-plan-section:first-of-type.checked::before {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        border-bottom-left-radius: 0;
    }

    .gh-portal-plan-section:last-of-type.checked::before {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 0;
    }

    .gh-portal-input {
        font-size: 1.4rem;
        margin-bottom: 16px;
        height: 36px;
    }

    .gh-portal-content {
        padding: 24px;
    }

    .gh-portal-popup-container footer {
        padding-right: 24px;
        padding-bottom: 24px;
        padding-left: 24px;
    }

    .gh-portal-signup-header,
    .gh-portal-signin-header {
        padding-bottom: 16px;
    }

    .gh-portal-account-main {
        padding: 24px;
    }
}

@media (min-width: 768px) and (max-height: 768px) {

    .gh-portal-signup-header,
    .gh-portal-signin-header {
        margin-top: -12px;
        padding-bottom: 16px;
    }
}

@-webkit-keyframes popup-mobile {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1.0;
    }
}

@keyframes popup-mobile {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1.0;
    }
}

.gh-portal-links-table {
    width: 100%;
}

.gh-portal-links-table tr td {
    white-space: nowrap;
    padding: 4px 12px 4px 0;
}

.gh-portal-links-table tr.header td {
    border-bottom: 1px solid #eaeaea;
    border-bottom: 1px solid var(--grey12);
}

.gh-portal-links-table tr.header h4.toggle {
    font-weight: 400;
    color: var(--brandcolor);
    cursor: pointer;
}

.gh-portal-links-table tr td:last-of-type {
    text-align: right;
    padding-right: 0;
}

.gh-portal-links-table tr.header .toggle-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Colors
    /* ----------------------------------------------------- */
:root {
    --black: #000;
    --grey0: #1d1d1d;
    --grey1: #333;
    --grey2: #3d3d3d;
    --grey3: #474747;
    --grey4: #515151;
    --grey5: #686868;
    --grey6: #7f7f7f;
    --grey7: #979797;
    --grey8: #aeaeae;
    --grey9: #c5c5c5;
    --grey10: #dcdcdc;
    --grey11: #e1e1e1;
    --grey12: #eaeaea;
    --grey13: #f9f9f9;
    --white: #fff;
    --red: #f02525;
    --yellow: #FFDC15;
    --green: #7FC724;
}

/* Globals
    /* ----------------------------------------------------- */
html {
    font-size: 62.5%;
    height: 100%;
}

/* body {
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1.6rem;
    height: 100%;
    line-height: 1.6em;
    font-weight: 400;
    font-style: normal;
    color: var(--grey4);
    box-sizing: border-box;
} */

*,
::after,
::before {
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    line-height: 1.15em;
    padding: 0;
    margin: 0;
}

h1 {
    font-size: 31px;
    font-weight: 500;
    letter-spacing: 0.2px;
}

h2 {
    font-size: 23px;
    font-weight: 500;
    letter-spacing: 0.2px;
}

h3 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.2px;
}

p {
    font-size: 15px;
    line-height: 1.55em;
    margin-bottom: 24px;
}

strong {
    font-weight: 600;
}

a,
.gh-portal-link {
    cursor: pointer;
}

svg {
    box-sizing: content-box;
}

.gh-portal-btn-main {
    box-shadow: none;
    position: relative;
    height: 46px;
    border: none;
}

.gh-portal-btn-main:hover {
    box-shadow: none;
    border: none;
}

.gh-portal-btn-primary:hover::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    background: rgba(255, 255, 255, 0.08);
    border-radius: 5px;
    border: none;
}

.gh-portal-btn-destructive:hover {
    color: #f02525;
    color: var(--red);
    border-color: #f02525;
    border-color: var(--red);
}

.gh-portal-loadingicon {
    position: absolute;
    left: 50%;
    display: inline-block;
    margin-left: -19px;
    height: 31px;
}

.gh-portal-loadingicon path,
.gh-portal-loadingicon rect {
    fill: #fff;
}

.gh-portal-loadingicon.dark path,
.gh-portal-loadingicon.dark rect {
    fill: #1d1d1d;
}

.gh-portal-signup-logo {
    position: relative;
    display: block;
    background-position: 50%;
    background-size: cover;
    border-radius: 2px;
    width: 56px;
    height: 56px;
    margin: 12px 0 10px;
}

.gh-portal-signup-header,
.gh-portal-signin-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 12px;
}

.gh-portal-signup-header.nodivider {
    border: none;
    margin-bottom: 0;
}

.gh-portal-signup-message {
    display: flex;
    justify-content: center;
    color: #515151;
    color: var(--grey4);
    font-size: 1.3rem;
    letter-spacing: 0.2px;
    margin-top: 8px;
}

.gh-portal-signup-message button {
    font-size: 1.3rem;
    font-weight: 400;
    margin-left: 4px;
}

.gh-portal-content.signup {
    /* background: linear-gradient(#fff 30%, hsla(0, 0%, 100%, 0)),
        linear-gradient(hsla(0, 0%, 100%, 0), #fff 70%) 0 100%,
        linear-gradient(#fff, transparent),
        linear-gradient(transparent, rgba(0, 0, 0, .08)) 0 100%; */
    background-repeat: no-repeat;
    background-color: #f9f9f9;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
}

footer.gh-portal-signup-footer,
footer.gh-portal-signin-footer {
    padding-top: 24px;
    height: 132px;
}


.gh-portal-content.signup.adminhome {
    max-height: calc(100% - 80px);
    height: 100%;
    padding-bottom: 0;
}

.gh-portal-content.signup.adminhome.desktop {
    max-height: calc(100% - 80px);
    background-color: transparent;
}

.gh-portal-content.signup,
.gh-portal-content.signin {
    max-height: calc(100vh - 135px);
    padding-bottom: 0;
}

.gh-portal-content.fullscreen {
    height: 100%;
    overflow-y: scroll;
    max-height: 100%;
    padding-bottom: 0;
}

.gh-portal-content.fullscreen.grey {
    background-color: #f9f9f9;
    background-color: var(--grey13);
}

.gh-portal-content.signup .gh-portal-section {
    margin-bottom: 0;
}

.gh-portal-content.signup.noplan {
    margin-bottom: -8px;
}

.gh-portal-content.signup.single-field {
    margin-bottom: 0;
}

.gh-portal-content.signup.single-field .gh-portal-input,
.gh-portal-content.signin .gh-portal-input {
    margin-bottom: 8px;
}

.gh-portal-content.signup.single-field+.gh-portal-signup-footer,
footer.gh-portal-signin-footer {
    padding-top: 12px;
}

.gh-portal-content.signin .gh-portal-section {
    margin-bottom: 0;
}

.gh-portal-content.signup.single-field+footer.gh-portal-signup-footer,
.gh-portal-content.signin+footer.gh-portal-signin-footer {
    height: 120px;
}


/** Custom Style overrides **/

.ant-form-item {
    margin-bottom: 12px;
}

.ant-input[disabled] {
    color: black !important;
    background-color: #fff !important;
}

.ant-picker-input>input[disabled] {
    color: black !important;
}

textarea.ant-input {
    resize: none;
}

.gh-portal-list-detail.info textarea.ant-input {
    padding: 0;
    font-size: 1.3rem;
}

.gh-portal-list-detail.info .ant-picker-input input {
    font-size: 1.3rem;
}

.editable-input .ant-select-selector {
    border: 0;
}

.nh-clientlist-item:hover {
    background: #f9f9f9;
}

.gh-portal-list-detail.info .ant-select-selector {
    padding-left: 0 !important;
}
.nh-modal-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: 'hidden';
}

.nh-frame-common {
    margin: auto;
    position: relative;
    padding: 0;
    outline: 0;
    width: 100%;
    opacity: 1;
    overflow: hidden;
    height: 100%;
}
.gh-portal-btn-back,
.gh-portal-btn-back:hover {
    box-shadow: none;
    height: unset;
    min-width: unset;
    background: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: 0.2px;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    transition: all .25s ease;
    outline: none;
}

.gh-portal-btn-back:hover {
    color: var(--grey1);
    transform: translateX(-4px);
}

.gh-portal-btn-back svg {
    width: 16px;
    height: 16px;
}
.gh-portal-account-main {
    background: var(--grey13);
    padding: 32px 32px 0;
}

.gh-portal-account-main.client-home {
    padding: 12px 12px 0;
}

.gh-portal-account-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 32px;
}

.gh-portal-account-header .gh-portal-avatar {
    margin: 6px 0 8px !important;
}

.gh-portal-account-data {
    margin-bottom: 32px;
}

footer.gh-portal-account-footer {
    display: flex;
    padding: 32px;
    height: 104px;
    border-top: 1px solid #eaeaea;
}

.gh-portal-account-footer.paid {
    margin-top: 12px;
}

.gh-portal-account-footermenu {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.gh-portal-account-footerright {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
}

.gh-portal-account-footermenu li {
    margin-right: 16px;
}

.gh-portal-account-footermenu li:last-of-type {
    margin-right: 0;
}

.gh-portal-freeaccount-newsletter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
}

.gh-portal-freeaccount-newsletter .label {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.gh-portal-free-ctatext {
    margin-top: -12px;
}

.gh-portal-cancelcontinue-container {
    margin: 24px 0 32px;
}

.gh-portal-account-wrapper {
    overflow-y: scroll;
    max-height: calc(100vh - 60px);
}

.screen-selector-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.ant-table-tbody > tr > td.ant-table-cell.editable-input {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: var(--white);
    border-radius: 3px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.05)
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background: var(--white);
    border-radius: 0px 0px 3px 3px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
}

.ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
}
.admin-home-main-container.desktop {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
}

.admin-home-main-container.desktop .admin-home-client-list {
    flex: 30% 1;
    height: 100%;
    display: flex;
    z-index: 100;
    overflow: hidden;
    position: relative;
    min-width: 420px;
    background: red;
    box-shadow: 5px 10px #888888;
    flex-direction: column;
    background-color: #fff6f6;
}
.admin-home-main-container.desktop .admin-home-client-details {
    flex: 70% 1;
    height: 100%;
    display: flex;
    z-index: 100;
    overflow: hidden;
    position: relative;
    background: #fffcf8;
    box-shadow: inset 0 0 5px #ababab;
    flex-direction: column;
}
.nh-profile-component *,
.nh-profile-component :after,
.nh-profile-component :before {
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #e2e8f0;
    border-right-color: #e2e8f0;
    border-bottom-color: #e2e8f0;
    border-left-color: #e2e8f0;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}

.nh-profile-component .gmtmqV {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.nh-profile-component .fxWvvr {
    position: relative;
}

.nh-profile-component .bcMPWx {
    font-family: Inter, system-ui;
    min-height: 100vh;
    color: #243e63;
    padding: 2rem;
    overflow: hidden;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    overflow-x: hidden;
    overflow-y: hidden;
}

.nh-profile-component .hMFSBk {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.nh-profile-component .kndJFj {
    margin-right: auto;
    max-width: 32rem;
}

.nh-profile-component .cpJeZt {
    margin-left: auto;
    position: relative;
    margin-top: 4rem;
}

.nh-profile-component blockquote,
.nh-profile-component dd,
.nh-profile-component dl,
.nh-profile-component figure,
.nh-profile-component h1,
.nh-profile-component h2,
.nh-profile-component h3,
.nh-profile-component h4,
.nh-profile-component h5,
.nh-profile-component h6,
.nh-profile-component hr,
.nh-profile-component p,
.nh-profile-component pre {
    margin: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.nh-profile-component h1,
.nh-profile-component h2,
.nh-profile-component h3,
.nh-profile-component h4,
.nh-profile-component h5,
.nh-profile-component h6 {
    font-size: inherit;
    font-weight: inherit;
}

.nh-profile-component .hIGAKr {
    font-size: 2.25rem;
    font-weight: 900;
    letter-spacing: 0.025em;
    text-align: left;
    color: #3c0d99;
    line-height: 1.375;
}

.nh-profile-component .kfPazA {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.625;
    margin-top: 1rem;
    color: #4a5568;
    max-width: 32rem;
}

.nh-profile-component a {
    background-color: transparent;
}

.nh-profile-component a {
    color: inherit;
    text-decoration: inherit;
    -webkit-text-decoration-line: inherit;
            text-decoration-line: inherit;
    -webkit-text-decoration-style: inherit;
            text-decoration-style: inherit;
    -webkit-text-decoration-color: inherit;
            text-decoration-color: inherit;
}

.nh-profile-component .jgLLHN {
    padding: 1.25rem 2rem;
    font-weight: 700;
    background-color: #6abbe5;
    color: #f7fafc;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 300ms;
    margin-top: 2rem;
    display: inline-block;
    width: 14rem;
    letter-spacing: 0.025em;
    text-align: center;
    border-radius: 9999px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
}

.nh-profile-component fieldset,
.nh-profile-component ol,
.nh-profile-component ul {
    margin: 0;
    padding: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}

.nh-profile-component ol,
.nh-profile-component ul {
    list-style: none;
    list-style-position: outside;
    list-style-position: initial;
    list-style-image: none;
    list-style-image: initial;
    list-style-type: none;
}

.nh-profile-component .bmDITp {
    margin-top: 3rem;
    line-height: 2;
}

.nh-profile-component .fibciF {
    position: relative;
    z-index: 40;
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.nh-profile-component .eFjboY {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #e2e8f0;
    border-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.nh-profile-component .dedQjC {
    display: flex;
    align-items: center;
}

.nh-profile-component img {
    border-style: none;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}

.nh-profile-component img {
    border-style: solid;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
}

.nh-profile-component audio,
.nh-profile-component canvas,
.nh-profile-component embed,
.nh-profile-component iframe,
.nh-profile-component img,
.nh-profile-component object,
.nh-profile-component svg,
.nh-profile-component video {
    display: block;
    vertical-align: middle;
}

.nh-profile-component img,
.nh-profile-component video {
    max-width: 100%;
    height: auto;
}

.nh-profile-component .bjZZZt {
    max-width: 100%;
    width: 24rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    position: relative;
    z-index: 20;
}

.nh-profile-component .gDkIbv {
    pointer-events: none;
    z-index: 10;
    position: absolute;
    right: 0px;
    bottom: 0px;
    --transform-translate-x: -33px;
    --transform-translate-y: -59px;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
    height: 8rem;
    width: 8rem;
    opacity: 0.25;
    color: #1a202c;
    fill: currentcolor;
}

.nh-profile-component .bOSqiS {
    max-width: 24rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    position: relative;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 20;
    padding: 1.5rem 2rem;
    background-color: #3c0d99;
    color: #cbd5e0;
    font-weight: 500;
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
    font-size: 0.875rem;
    line-height: 1.625;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.nh-profile-component .lIFnV {
    width: 1.25rem;
    height: 1.25rem;
    color: #6abbe5;
}

.nh-profile-component .iJxkxO {
    margin-left: 0.5rem;
    font-weight: 500;
    color: #4a5568;
}

.nh-profile-component .ifZihH {
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: 0px;
    left: 0px;
    color: #f7fafc;
    --transform-translate-x: 0.25rem;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
    opacity: 0.1;
}

.nh-profile-component .daccCm {
    margin-top: 1rem;
    font-weight: 700;
}

.nh-profile-component .hfkrUv {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: #a0aec0;
}
.firebaseui-container {
    background: rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    min-height: 150px !important;
    padding-top: 10px;
    border-radius: 20px !important;
    box-shadow: none;
}

.firebaseui-container.firebaseui-page-provider-sign-in {
    background: transparent;
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}

.firebaseui-container.firebaseui-id-page-callback {
    background: transparent;
    box-shadow: none;
    margin-top: 40px;
    height: 84px;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}

.firebaseui-card-header {
    display: none;
}

.firebaseui-subtitle,
.firebaseui-text {
    color: rgba(255, 255, 255, 0.87);
}

.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
    background: #3fb55a !important;
}

.firebaseui-form-actions {
    text-align: center !important;
}

.firebaseui-id-dismiss-info-bar {
    display: block;
}

.firebaseui-info-bar {
    border: 0;
    border-radius: 10px;
    left: 5%;
    right: 5%;
    top: 10%;
    bottom: 10%;
}

.firebaseui-tos, .firebaseui-phone-tos {
    display: none;
}

.admin-home-main-container.desktop {
  width: 100%;
  height: 100%;
  display: flex;
  flex-grow: 1;
}

.admin-home-main-container.desktop .admin-home-client-list {
    flex: 30%;
    height: 100%;
    display: flex;
    z-index: 100;
    overflow: hidden;
    position: relative;
    min-width: 420px;
    background: red;
    box-shadow: 5px 10px #888888;
    flex-direction: column;
    background-color: #fff6f6;
}
.admin-home-main-container.desktop .admin-home-client-details {
    flex: 70%;
    height: 100%;
    display: flex;
    z-index: 100;
    overflow: hidden;
    position: relative;
    background: #fffcf8;
    box-shadow: inset 0 0 5px #ababab;
    flex-direction: column;
}
.gh-portal-btn-back,
.gh-portal-btn-back:hover {
    box-shadow: none;
    height: unset;
    min-width: unset;
    background: none;
    padding: 0;
    margin: 0;
    box-shadow: none;
    border: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: 0.2px;
    text-align: center;
    white-space: nowrap;
    text-decoration: none;
    cursor: pointer;
    transition: all .25s ease;
    outline: none;
}

.gh-portal-btn-back:hover {
    color: var(--grey1);
    transform: translateX(-4px);
}

.gh-portal-btn-back svg {
    width: 16px;
    height: 16px;
}
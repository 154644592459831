.firebaseui-container {
    background: rgba(0, 0, 0, 0.05);
    margin-bottom: 15px;
    min-height: 150px !important;
    padding-top: 10px;
    border-radius: 20px !important;
    box-shadow: none;
}

.firebaseui-container.firebaseui-page-provider-sign-in {
    background: transparent;
    box-shadow: none;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}

.firebaseui-container.firebaseui-id-page-callback {
    background: transparent;
    box-shadow: none;
    margin-top: 40px;
    height: 84px;
    min-height: 0;
    margin-bottom: 0;
    padding-top: 0;
}

.firebaseui-card-header {
    display: none;
}

.firebaseui-subtitle,
.firebaseui-text {
    color: rgba(255, 255, 255, 0.87);
}

.firebaseui-form-actions .mdl-button--raised.mdl-button--colored.firebaseui-button {
    background: #3fb55a !important;
}

.firebaseui-form-actions {
    text-align: center !important;
}

.firebaseui-id-dismiss-info-bar {
    display: block;
}

.firebaseui-info-bar {
    border: 0;
    border-radius: 10px;
    left: 5%;
    right: 5%;
    top: 10%;
    bottom: 10%;
}

.firebaseui-tos, .firebaseui-phone-tos {
    display: none;
}
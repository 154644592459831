.nh-modal-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: 'hidden';
}

.nh-frame-common {
    margin: auto;
    position: relative;
    padding: 0;
    outline: 0;
    width: 100%;
    opacity: 1;
    overflow: hidden;
    height: 100%;
}
.nh-profile-component *,
.nh-profile-component :after,
.nh-profile-component :before {
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
    border-top-width: 0px;
    border-right-width: 0px;
    border-bottom-width: 0px;
    border-left-width: 0px;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
    border-top-color: #e2e8f0;
    border-right-color: #e2e8f0;
    border-bottom-color: #e2e8f0;
    border-left-color: #e2e8f0;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
}

.nh-profile-component .gmtmqV {
    padding-top: 5rem;
    padding-bottom: 5rem;
}

.nh-profile-component .fxWvvr {
    position: relative;
}

.nh-profile-component .bcMPWx {
    font-family: Inter, system-ui;
    min-height: 100vh;
    color: #243e63;
    padding: 2rem;
    overflow: hidden;
    padding-top: 2rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    overflow-x: hidden;
    overflow-y: hidden;
}

.nh-profile-component .hMFSBk {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
}

.nh-profile-component .kndJFj {
    margin-right: auto;
    max-width: 32rem;
}

.nh-profile-component .cpJeZt {
    margin-left: auto;
    position: relative;
    margin-top: 4rem;
}

.nh-profile-component blockquote,
.nh-profile-component dd,
.nh-profile-component dl,
.nh-profile-component figure,
.nh-profile-component h1,
.nh-profile-component h2,
.nh-profile-component h3,
.nh-profile-component h4,
.nh-profile-component h5,
.nh-profile-component h6,
.nh-profile-component hr,
.nh-profile-component p,
.nh-profile-component pre {
    margin: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
}

.nh-profile-component h1,
.nh-profile-component h2,
.nh-profile-component h3,
.nh-profile-component h4,
.nh-profile-component h5,
.nh-profile-component h6 {
    font-size: inherit;
    font-weight: inherit;
}

.nh-profile-component .hIGAKr {
    font-size: 2.25rem;
    font-weight: 900;
    letter-spacing: 0.025em;
    text-align: left;
    color: #3c0d99;
    line-height: 1.375;
}

.nh-profile-component .kfPazA {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.625;
    margin-top: 1rem;
    color: #4a5568;
    max-width: 32rem;
}

.nh-profile-component a {
    background-color: transparent;
}

.nh-profile-component a {
    color: inherit;
    text-decoration: inherit;
    text-decoration-line: inherit;
    text-decoration-style: inherit;
    text-decoration-color: inherit;
}

.nh-profile-component .jgLLHN {
    padding: 1.25rem 2rem;
    font-weight: 700;
    background-color: #6abbe5;
    color: #f7fafc;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
    transition-duration: 300ms;
    margin-top: 2rem;
    display: inline-block;
    width: 14rem;
    letter-spacing: 0.025em;
    text-align: center;
    border-radius: 9999px;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    border-top-left-radius: 9999px;
    border-top-right-radius: 9999px;
    border-bottom-right-radius: 9999px;
    border-bottom-left-radius: 9999px;
}

.nh-profile-component fieldset,
.nh-profile-component ol,
.nh-profile-component ul {
    margin: 0;
    padding: 0;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
}

.nh-profile-component ol,
.nh-profile-component ul {
    list-style: none;
    list-style-position: initial;
    list-style-image: initial;
    list-style-type: none;
}

.nh-profile-component .bmDITp {
    margin-top: 3rem;
    line-height: 2;
}

.nh-profile-component .fibciF {
    position: relative;
    z-index: 40;
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
}

.nh-profile-component .eFjboY {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #e2e8f0;
    border-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.nh-profile-component .dedQjC {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}

.nh-profile-component img {
    border-style: none;
    border-top-style: none;
    border-right-style: none;
    border-bottom-style: none;
    border-left-style: none;
}

.nh-profile-component img {
    border-style: solid;
    border-top-style: solid;
    border-right-style: solid;
    border-bottom-style: solid;
    border-left-style: solid;
}

.nh-profile-component audio,
.nh-profile-component canvas,
.nh-profile-component embed,
.nh-profile-component iframe,
.nh-profile-component img,
.nh-profile-component object,
.nh-profile-component svg,
.nh-profile-component video {
    display: block;
    vertical-align: middle;
}

.nh-profile-component img,
.nh-profile-component video {
    max-width: 100%;
    height: auto;
}

.nh-profile-component .bjZZZt {
    max-width: 100%;
    width: 24rem;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    position: relative;
    z-index: 20;
}

.nh-profile-component .gDkIbv {
    pointer-events: none;
    z-index: 10;
    position: absolute;
    right: 0px;
    bottom: 0px;
    --transform-translate-x: -33px;
    --transform-translate-y: -59px;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
    height: 8rem;
    width: 8rem;
    opacity: 0.25;
    color: #1a202c;
    fill: currentcolor;
}

.nh-profile-component .bOSqiS {
    max-width: 24rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    position: relative;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 20;
    padding: 1.5rem 2rem;
    background-color: #3c0d99;
    color: #cbd5e0;
    font-weight: 500;
    --transform-translate-x: 0;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
    font-size: 0.875rem;
    line-height: 1.625;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.nh-profile-component .lIFnV {
    width: 1.25rem;
    height: 1.25rem;
    color: #6abbe5;
}

.nh-profile-component .iJxkxO {
    margin-left: 0.5rem;
    font-weight: 500;
    color: #4a5568;
}

.nh-profile-component .ifZihH {
    width: 4rem;
    height: 4rem;
    position: absolute;
    top: 0px;
    left: 0px;
    color: #f7fafc;
    --transform-translate-x: 0.25rem;
    --transform-translate-y: 0;
    --transform-rotate: 0;
    --transform-skew-x: 0;
    --transform-skew-y: 0;
    --transform-scale-x: 1;
    --transform-scale-y: 1;
    transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
    opacity: 0.1;
}

.nh-profile-component .daccCm {
    margin-top: 1rem;
    font-weight: 700;
}

.nh-profile-component .hfkrUv {
    margin-top: 0.25rem;
    font-size: 0.875rem;
    color: #a0aec0;
}
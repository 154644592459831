.gh-portal-account-main {
    background: var(--grey13);
    padding: 32px 32px 0;
}

.gh-portal-account-main.client-home {
    padding: 12px 12px 0;
}

.gh-portal-account-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 32px;
}

.gh-portal-account-header .gh-portal-avatar {
    margin: 6px 0 8px !important;
}

.gh-portal-account-data {
    margin-bottom: 32px;
}

footer.gh-portal-account-footer {
    display: flex;
    padding: 32px;
    height: 104px;
    border-top: 1px solid #eaeaea;
}

.gh-portal-account-footer.paid {
    margin-top: 12px;
}

.gh-portal-account-footermenu {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.gh-portal-account-footerright {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: flex-end;
}

.gh-portal-account-footermenu li {
    margin-right: 16px;
}

.gh-portal-account-footermenu li:last-of-type {
    margin-right: 0;
}

.gh-portal-freeaccount-newsletter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
}

.gh-portal-freeaccount-newsletter .label {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.gh-portal-free-ctatext {
    margin-top: -12px;
}

.gh-portal-cancelcontinue-container {
    margin: 24px 0 32px;
}

.gh-portal-account-wrapper {
    overflow-y: scroll;
    max-height: calc(100vh - 60px);
}

.screen-selector-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.ant-table-tbody > tr > td.ant-table-cell.editable-input {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    background: var(--white);
    border-radius: 3px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.07), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.05)
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
    background: var(--white);
    border-radius: 0px 0px 3px 3px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
}

.ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
}